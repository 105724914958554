<template>
    <div class="content-wrapper">
        <PageHeader screenName="Cadastrar nova" :linkItems="linkItems" />

        <TimedAlert variant="success" message="Unidade salva com sucesso!" :show="savedAlert" />

        <main class="card p-2">
            <section class="form">
                <validation-observer ref="UnidadesRules" v-slot="{ validate }">
                    <b-form class="section-unidade">
                        <b-row>
                            <b-col lg="4" md="6" sm="12" class="mb-1">
                                <Tipo @updated="form.tipo = $event" :key="indice.tipo" :cadastro="true"/>
                            </b-col>
                            <b-col lg="4" md="6" sm="12" class="mb-1">
                                <DepartamentoRegional @updated="handleSelectDepartamento" :key="indice.departamento" />
                            </b-col>
                            <b-col lg="4" md="6" sm="12" class="mb-1">
                                <Regiao @updated="form.regiao = $event" :departamento="form.departamentoRegional"
                                    :key="indice.regiao" />
                            </b-col>
                            <b-col lg="6" md="6" sm="12" class="mb-1">
                                <NomeUnidadeOperacional @updated="form.nomeUnidadeOperacional = $event"
                                    :key="indice.nomeUnidadeOperacional" />
                            </b-col>
                            <b-col lg="6" md="6" sm="12" class="mb-1">
                                <Cnpj @updated="form.cnpj = $event" :key="indice.cnpj" />
                            </b-col>
                            <b-col lg="6" md="6" sm="12" class="mb-1">
                                <CodigoSmdUnidadeOperacional @updated="form.codigoSMD = $event"
                                    :key="indice.codigoSMD" />
                            </b-col>
                            <b-col lg="6" md="6" sm="12" class="mb-1">
                                <CodigoParaIntegracaoExterna @updated="form.codigoIntegracaoExterna = $event"
                                    :key="indice.codigoIntegracaoExterna" />
                            </b-col>
                            <b-col lg="4" md="6" sm="12" class="mb-1">
                                <Cep @updated="handleSelectCep" :key="indice.cep" />
                            </b-col>
                            <b-col lg="8" md="6" sm="12" class="mb-1">
                                <Logradouro @updated="form.logradouro = $event" :value="form.logradouro"
                                    :key="indice.logradouro" />
                            </b-col>
                            <b-col lg="4" md="6" sm="12" class="mb-1">
                                <Numero @updated="form.numero = $event" :key="indice.numero" />
                            </b-col>
                            <b-col lg="4" md="6" sm="12" class="mb-1">
                                <PontoReferencia @updated="form.pontoReferencia = $event"
                                    :key="indice.pontoReferencia" />
                            </b-col>
                            <b-col lg="4" md="6" sm="12" class="mb-1">
                                <Bairro @updated="form.bairro = $event" :value="form.bairro" :key="indice.bairro" />
                            </b-col>
                            <b-col lg="6" md="6" sm="12" class="mb-1">
                                <Estado @updated="handleSelectEstado" :value="form.estado.uf" :key="indice.estado"
                                    :disabled="consultaCep.temCidade" />
                            </b-col>
                            <b-col lg="6" md="6" sm="12" class="mb-1">
                                <Cidade @updated="form.cidade = $event" :estado="form.estado" :value="consultaCep.city"
                                    @setCidades="cidades = $event" :key="indice.cidade"
                                    :disabled="consultaCep.temCidade" />
                            </b-col>
                            <b-col lg="6" md="6" sm="12" class="mb-1">
                                <NomeResponsavel @updated="form.nomeResponsavel = $event"
                                    :key="indice.nomeResponsavel" />
                            </b-col>
                            <b-col lg="6" md="6" sm="12" class="mb-1">
                                <EmailResponsavel @updated="form.emailResponsavel = $event"
                                    :key="indice.emailResponsavel" />
                            </b-col>
                        </b-row>

                        <b-row>
                            <VincularCidades :departamento="form.departamentoRegional" :cidadeBancoEdicao="[]"
                                @updated="form.cidadesVinculadas = $event" :key="indice.cidadesVinculadas" />
                        </b-row>

                        <b-row class="buttons mt-3">
                            <b-col>
                                <b-button id="save-unidade" class="mr-2" variant="custom-blue"
                                    @click.prevent="submitForm(true)" :disabled="desabilitarSaving">
                                    <feather-icon icon="CheckIcon" class="mr-50" />
                                    <span class="align-middle">Salvar unidade</span>
                                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status"
                                        aria-hidden="true"></span>
                                </b-button>
                                <b-button id="save-new-unidade" class="mr-2" variant="custom-blue"
                                    @click.prevent="submitForm(false)" :disabled="desabilitarSavingAndNew">
                                    <feather-icon icon="CheckIcon" class="mr-50" />
                                    <span class="align-middle">Salvar e cadastrar um nova</span>
                                    <span v-if="savingAndNew" class="spinner-border spinner-border-sm ml-1"
                                        role="status" aria-hidden="true"></span>
                                </b-button>
                                <b-button id="cancel-register-unidade" variant="outline-danger"
                                    @click.prevent="alteraVisibilidadeConfirmModal(true)">
                                    <feather-icon icon="XIcon" class="mr-50" />
                                    <span class="align-middle">Cancelar</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </section>

        </main>

        <section>
            <ConfirmModal itemIdentifier="unidade" :modalShow="modal.showModal" :typeModal="modal.typeModal"
                @confirmed="redirecionarParaOutraPagina('unidade-list')"
                @canceled="alteraVisibilidadeConfirmModal(false)" />

            <DepartamentoModal :modalShow="modalErroRegiao.showModal" :typeModal="modalErroRegiao.caseModal"
                @confirmed="redirecionarParaOutraPagina('departamento-view')" />

            <SucessoModal
                :modalShow="modalSuccess.showModal"
                :typeModal="modalSuccess.typeModal"
                :caseModal="modalSuccess.caseModal"
                @confirmed="redirecionarParaOutraPagina('unidade-list')"
            />

            <ModalShowError
                :modalShow="modalError.showModal"
                :typeModal="modalError.typeModal"
                :infoText="modalError.infoText"
                @confirmed="alteraVisibilidadeErrorModal(false)"
            />
        </section>
    </div>
</template>

<script>
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import { getUserData } from "@/auth/utils";
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LinkCitiesUnits from '@/views/components/custom/LinkCitiesUnits/LinkCitiesUnits.vue';
import Tipo from './parts/Tipo.vue';
import DepartamentoRegional from './parts/DepartamentoRegional.vue';
import NomeUnidadeOperacional from './parts/NomeUnidadeOperacional.vue';
import Cnpj from './parts/Cnpj.vue';
import CodigoSmdUnidadeOperacional from './parts/CodigoSmdUnidadeOperacional.vue';
import CodigoParaIntegracaoExterna from './parts/CodigoParaIntegracaoExterna.vue';
import Cep from './parts/Cep.vue';
import Logradouro from './parts/Logradouro.vue';
import Numero from './parts/Numero.vue';
import PontoReferencia from './parts/PontoReferencia.vue';
import Bairro from './parts/Bairro.vue';
import Estado from './parts/Estado.vue';
import Cidade from './parts/Cidade.vue';
import NomeResponsavel from './parts/NomeResponsavel.vue';
import EmailResponsavel from './parts/EmailResponsavel.vue';
import Regiao from './parts/Regiao.vue';
import VincularCidades from './parts/VincularCidades.vue';

import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import DepartamentoModal from '@/views/components/custom/modals/ModalErrorDepartamento.vue';
import handleErrorsService from './Services/handleErros';
import { getPayload, temCamposObrigadorioVazio } from './Services/handlePayload.js';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import ModalShowError from '@/views/components/custom/modals/ModalShowError.vue';

export default {
    title: 'Cadastrar unidade',
    components: {
        PageHeader,
        BRow, BCol, BForm, BFormGroup, BFormInput, BButton,
        ValidationProvider, ValidationObserver,
        LinkCitiesUnits,
        Tipo,
        DepartamentoRegional,
        NomeUnidadeOperacional,
        Cnpj,
        CodigoSmdUnidadeOperacional,
        CodigoParaIntegracaoExterna,
        Cep,
        Logradouro,
        Numero,
        PontoReferencia,
        Bairro,
        Estado,
        Cidade,
        NomeResponsavel,
        EmailResponsavel,
        ConfirmModal,
        DepartamentoModal,
        Regiao,
        VincularCidades,
        TimedAlert,
        SucessoModal,
        ModalShowError,
    },
    data() {
        return {
            userData: getUserData(),
            linkItems: [
                { name: 'Unidades Operacionais', routeName: 'unidade-list', },
                { name: 'Cadastrar nova', active: true }
            ],

            isLoading: true,
            isDisabledField: true,
            saving: false,
            savingAndNew: false,
            submitting: false,
            savedAlert: false,
            cidades: [],
            semCidadeVinculada: false,
            consultaCep: {
                cep: null,
                state: null,
                city: null,
                neighborhood: null,
                street: null,
                service: null,
                temCidade: false,
            },
            indice: {
                departamento: 0,
                estado: 0,
                cidade: 0,
                logradouro: 0,
                bairro: 0,
                cidadesVinculadas: 0,
                tipo: 0,
                departamentoRegional: 0,
                nomeUnidadeOperacional: 0,
                cnpj: 0,
                codigoSMD: 0,
                codigoIntegracaoExterna: 0,
                cep: 0,
                numero: 0,
                pontoReferencia: 0,
                nomeResponsavel: 0,
                emailResponsavel: 0,
                regiao: 0,
            },
            form: {
                tipo: null,
                departamentoRegional: null,
                nomeUnidadeOperacional: null,
                cnpj: null,
                codigoSMD: null,
                codigoIntegracaoExterna: null,
                cep: null,
                logradouro: null,
                numero: null,
                pontoReferencia: null,
                bairro: null,
                estado: { uf: null },
                cidade: null,
                nomeResponsavel: null,
                emailResponsavel: null,
                cidadesVinculadas: null,
                regiao: null,
                idUnidadeContatoResponsavel: null,
                idUsuarioResponsavel: null,
                idUnidadeResponsavel: null,
            },
            modal: {
                showModal: false,
                typeModal: 'danger'
            },
            modalSuccess: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'criacao'
            },
            modalErroRegiao: {
                showModal: false,
                caseModal: 'sem-regiao-criacao-unidade'
            },
            modalError: {
                showModal: false,
                typeModal: 'error',
                infoText: ''
            },
        }
    },
    methods: {
        handleSelectDepartamento(departamento) {
            this.form.departamentoRegional = departamento
            this.form.regiao = null;
            this.indice.cidadesVinculadas++
            this.indice.regiao++
        },
        handleSelectEstado(estado) {
            this.form.estado = estado;
            this.indice.cidade++;
        },
        handleSelectCep(dados) {
            this.consultaCep = dados;
            this.consultaCep.temCidade = dados.city != null

            this.form.cep = dados.cep
            this.form.logradouro = dados.street
            this.form.bairro = dados.neighborhood
            this.form.estado = { uf: dados.state };
            this.form.cidade = dados.city;

            this.indice.logradouro++
            this.indice.bairro++
            this.indice.estado++;
            this.indice.cidade++;
        },
        salvar() {
            this.$http.post(this.$api.unidade(), this.payload).then(() => {
                if (this.saving) {
                    this.alteraVisibilidadeSucessoModal(true);
                    return;
                }

                this.cleanForm();

                this.savedAlert = !this.savedAlert;
            }).catch(({ response: { data, status } }) => {
                if (status == 409) {
                    this.alteraVisibilidadeDepartamentoModal(true)
                } else {
                    this.modalError.infoText = handleErrorsService.format(data.errors)
                    this.alteraVisibilidadeErrorModal(true)
                }

                this.saving = false;
                this.savingAndNew = false;
                this.submitting = false
            })
        },
        submitForm(somenteSalvar = true) {
            this.$refs.UnidadesRules.validate().then((isValid) => {
            if (isValid) {
                this.submitting = true;
                this.saving = somenteSalvar;
                this.savingAndNew = !somenteSalvar;
                this.savedAlert = false
                this.salvar();
            }
            });
        },
        alteraVisibilidadeConfirmModal(show) {
            this.modal.showModal = show;
        },
        alteraVisibilidadeSucessoModal(show) {
            this.modalSuccess.showModal = show;
        },
        alteraVisibilidadeDepartamentoModal(show) {
            this.modalErroRegiao.showModal = show;
        },
        alteraVisibilidadeErrorModal(show) {
            this.modalError.showModal = show;
        },
        redirecionarParaOutraPagina(name) {
            this.alteraVisibilidadeConfirmModal(false);
            this.alteraVisibilidadeSucessoModal(false);
            this.alteraVisibilidadeDepartamentoModal(false);
            this.$router.push({ name });
        },
        cleanForm() {
            this.saving = false;
            this.savingAndNew = false;
            this.submitting = false;
            Object.keys(this.form).forEach(posicao => {
                this.form[posicao] = posicao == 'estado' ? { uf: null } : null;
            })
            Object.keys(this.indice).forEach(posicao => {
                this.indice[posicao]++
            })
        }
    },
    computed: {
        temCamposObrigadorioVazio() {
            return temCamposObrigadorioVazio(this.form)
        },
        payload() {
            return getPayload(this.form)
        },
        desabilitarSaving() {
            return this.submitting || this.temCamposObrigadorioVazio || this.saving;
        },
        desabilitarSavingAndNew() {
            return this.submitting || this.temCamposObrigadorioVazio || this.savingAndNew;
        },
    },
}

</script>
