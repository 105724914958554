<template>
    <div>

        <b-row class="w-100 m-0 px-2 pt-2">
            <h6>Cidades vinculadas a esta unidade operacional</h6>
            <p>
                Configure abaixo as cidades que serão atendidas por essa unidade operacional, selecionando e
                movendo-as para o quadro Cidades Vinculadas. O vínculo fará com que as empresas cadastradas com
                endereços nessas cidades tenham o atendimento sugerido para esta unidade operacional.
            </p>
        </b-row>

        <b-alert variant="danger" class="mb-0" :show="noCityLinkedAlert">
            <div class="alert-body">
                <feather-icon icon="AlertTriangleIcon" class="mr-50" />
                É necessário que ao menos uma cidade esteja vinculada a unidade.
            </div>
        </b-alert>
        <b-container fluidc class="d-flex container p-0">
            <b-row align-v="center" class="w-100 m-0">
                <b-col cols="5">
                    <h6 class="text-secondary">Cidades não vinculadas</h6>
                    <div class="border border-muted rounded containerCities">
                        <h6 v-if="(citiesNotLinked.length == 0) && (totalCities === 0)" class="text-muted infoText">
                            Selecione um departamento Regional para carregar as cidades.
                        </h6>
                        <h6 v-if="(totalCities !== 0) && (totalCities === citiesLinked.length)"
                            class="text-muted infoText">
                            Não há cidades disponíveis para vinculação.
                        </h6>
                        <b-form-group class="d-flex align-items-center">
                            <b-form-checkbox class="m-1" v-for="city in citiesNotLinked"
                                v-model="selectedCitiesNotLinked" :key="city.id_cidade" :value="city.id_cidade"
                                name="notLinkeedCities">{{ city.nome }}</b-form-checkbox>
                        </b-form-group>
                    </div>
                </b-col>
                <b-col cols="2">
                    <b-row class="d-flex flex-column flex-fill justify-content-center">
                        <b-button class="mb-1" variant="custom-blue"
                            @click.prevent="changeSideCities('selectedCitiesNotLinked', 'citiesNotLinked', 'citiesLinked')">
                            {{ 'Vincular >>' }} </b-button>
                        <b-button variant="custom-blue"
                            @click.prevent="changeSideCities('selectedCitiesLinked', 'citiesLinked', 'citiesNotLinked')">
                            {{ '<< Desvincular' }} </b-button>
                    </b-row>
                </b-col>
                <b-col cols="5">
                    <h6 class="text-secondary">Cidades vinculadas</h6>
                    <div class="border border-muted rounded containerCities">
                        <h6 v-if="citiesLinked.length == 0" class="text-muted infoText">Não há cidades vinculadas</h6>
                        <b-form-group>
                            <b-form-checkbox class="m-1" v-for="city in citiesLinked" v-model="selectedCitiesLinked"
                                :key="city.id_cidade" :value="city.id_cidade" name="linkedCities">{{ city.nome
                                }}</b-form-checkbox>
                        </b-form-group>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { BContainer, BRow, BCol, BFormCheckbox, BFormGroup, BButton, BAlert } from "bootstrap-vue";

export default {
    components: {
        BContainer, BRow, BCol,
        BFormGroup,
        BFormCheckbox,
        BButton, BAlert
    },

    props: {
        cities: {
            type: Object | null,
            required: true
        },
        ciityPhysicalLocation: {
            type: Object | null,
            required: true
        },
        citiesAlreadyLinked: {
            type: Object | null,
            required: false
        },
        noCityLinked: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            selectedCitiesLinked: [],
            selectedCitiesNotLinked: [],

            citiesNotLinked: [],
            citiesLinked: [],
            citiesLinkedWaiting: [],

            initialCities: [],
            initialLinkedCities: [],

            isEditing: false,
            physicalCitySelected: null,

            totalCities: 0,

            noCityLinkedAlert: false
        }
    },

    methods: {
        changeSideCities(selectedCities, fromSide, toSide) {
            this[selectedCities].map(city_id => {
                const citySelected = this[fromSide].find(city => city.id_cidade == city_id);

                if (citySelected) {
                    this[fromSide] = this[fromSide].filter(city => city.id_cidade !== city_id);
                    this[toSide].push(citySelected);
                }
            });

            this.sortCities(this[toSide]);
            this[selectedCities] = [];

            this.$emit('linkedCities', this.citiesLinked);

            if (this.citiesLinked.length > 0) {
                this.noCityLinkedAlert = false;
            }
        },

        sortCities(citiesSelected) {
            return citiesSelected.sort(function (city1, city2) {
                const cityName1 = city1.nome.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                const cityName2 = city2.nome.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                if (cityName1 < cityName2) { return -1; }
                if (cityName1 > cityName2) { return 1; }

                return 0;
            });
        },

        organizeCitiesBoxes() {
            this.citiesNotLinked = this.initialCities;

            this.citiesLinked.map(cityLinked => {
                this.citiesNotLinked = this.citiesNotLinked.filter(city => city.id_cidade !== cityLinked.id_cidade);
            });

            this.totalCities = this.citiesNotLinked.length;

            this.$emit('linkedCities', this.citiesLinked);
        }
    },

    watch: {
        cities(cities) {
            if (cities) {
                this.selectedCitiesNotLinked = [];
                this.selectedCitiesLinked = [];
                this.citiesNotLinked = cities;
                this.initialCities = cities;
                this.totalCities = cities.length;
                if (!this.isEditing) {
                    this.citiesLinked = [];
                }
            }

            this.citiesLinked = this.citiesLinkedWaiting;
            this.initialLinkedCities = this.citiesLinkedWaiting;

            this.organizeCitiesBoxes();
        },

        ciityPhysicalLocation(idCitySelected) {
            this.physicalCitySelected = idCitySelected;

            this.organizeCitiesBoxes();
        },

        citiesAlreadyLinked(cities) {
            if (cities) {
                this.isEditing = true;
                this.citiesLinkedWaiting = cities;
            }
        },

        noCityLinked(value) {
            this.noCityLinkedAlert = value;
        }
    }
}
</script>

<style scoped>
.container {
    min-height: 300px;
}

.containerCities {
    height: 250px;
    overflow-y: scroll;
}

.infoText {
    position: relative;
    top: 50%;
    text-align: center;
}
</style>